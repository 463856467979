import React from "react";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";

const ListItem = styled(animated(Link))`
  color: black;
  opacity: 0;
  text-align: center;
  text-decoration: none;
  h1 {
    margin: 0;
    transition: opacity 0.4s;
  }
  @media (max-width: 550px) {
    font-size: 2.9vw;
  }
`;

export const ExhibitionsList = ({ list, setThumbnailUrl }) => {
  const transitions = useTransition(list, {
    from: {
      opacity: 0,
      translateY: 10,
    },
    enter: {
      opacity: 1,
      translateY: 0,
    },
    leave: {
      opacity: 1,
      translateY: 0,
    },
    trail: 100,
  });

  const onMouse = (e, url) => {
    const exhibitionTitles = document.querySelectorAll("h1");
    const activeTitle = e.target;
    const thumbnail = document.querySelector("#thumbnail");
    setThumbnailUrl(url);
    if (e.type === "mouseenter") {
      exhibitionTitles.forEach((el) => (el.style.opacity = 0.2));
      activeTitle.style.opacity = 1;
      thumbnail.style.opacity = 1;
    }
    if (e.type === "mouseleave") {
      exhibitionTitles.forEach((el) => (el.style.opacity = 1));
      thumbnail.style.opacity = 0;
    }
  };

  return transitions((style, item) => (
    <ListItem
      key={`listItem-${item.id.N}`}
      onMouseEnter={(e) => onMouse(e, item.thumbnailUrl.S)}
      onMouseLeave={(e) => onMouse(e, item.thumbnailUrl.S)}
      style={style}
      to={`/exhibitions/${item.id.N}`}
    >
      <h1>{item.listTitle.S}</h1>
    </ListItem>
  ));
};

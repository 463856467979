import React from "react";

import { Image, Video } from "../components";

export const render = {
  lineFeedsAndImages: (text) => {
    return text
      .split("\n")
      .map((el, i) =>
        el.substring(0, 4) === "http" ? (
          <img alt="press" src={el} key={i} />
        ) : (
          <p key={i}>{el}</p>
        ),
      );
  },
  media: (media, setLoading) => {
    return media.map(({ M: el }, i) =>
      el.src ? (
        <Video
          key={i}
          ratio={el.ratio.S}
          src={el.src.S}
          title={el.iframeTitle.S}
        />
      ) : i === 0 ? (
        <Image data={el} key={i} onLoad={() => setLoading(false)} />
      ) : (
        <Image data={el} key={i} />
      ),
    );
  },
};

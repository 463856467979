export const transform = {
  aws: {
    objectToArrayOfArrayPairs: (obj) => {
      const array = [];
      for (let key in obj) {
        for (const type in obj[key]) {
          const pair = [key, obj[key][type]];
          array.push(pair);
        }
      }
      return array;
    },
  },
};

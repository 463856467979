import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import { Spinner } from "../components";
import { render, transform } from "../utils";
import { useExhibitions, useExhibitionsDispatch } from "../context/exhibitions";

const ActionButton = styled.button`
  border-radius: 999px;
  cursor: pointer;
  height: 10px;
  margin-left: 0.5em;
  transition:
    background 0.5s,
    transform 0.5s;
  width: 10px;
  &:hover {
    transform: scale(1.2);
  }
`;

const ActionsContainer = styled.div`
  bottom: 0;
  line-height: 10px;
  padding: 1em;
  position: fixed;
  right: 0;
  > button {
    background: ${({ darkMode }) => (darkMode ? "white" : "black")};
  }
  @media (max-width: 716px) {
    display: none;
  }
`;

const Content = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  transition: opacity 0.5s;
  @media (max-width: 632px) {
    padding: 0 1em;
  }
`;

const Header = styled.div`
  max-width: 600px;
  width: 100%;
`;

const MediaWrapper = styled.div`
  div {
    padding-bottom: ${({ spaceBetweenActive }) =>
      spaceBetweenActive ? "15em" : ""};
    transition: padding-bottom 0.5s;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 5em;
  max-width: 600px;
  width: 100%;
  img {
    transition: filter 0.5s;
  }
`;

export const Exhibition = () => {
  const exhibitions = useExhibitions();
  const dispatch = useExhibitionsDispatch();
  const [darkMode, setDarkMode] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [spaceBetweenActive, setSpaceBetweenActive] = useState(false);

  const toggleDarkMode = () => {
    const body = document.getElementsByTagName("body")[0];
    // handle kira's statement being an image
    const press = document.querySelector("img[alt='press']");
    const kira = press && id === "5";
    if (darkMode) {
      body.classList.remove("dark-mode");
      if (kira) {
        press.style.filter = "invert(0)";
      }
    } else {
      body.classList.add("dark-mode");
      if (kira) {
        press.style.filter = "invert(1)";
      }
    }
    setDarkMode(!darkMode);
  };

  const toggleSpaceBetween = () => {
    setSpaceBetweenActive(!spaceBetweenActive);
  };

  useEffect(() => {
    const getExhibition = async () => {
      try {
        const { data } = await axios.get(
          `https://api.goodenoughfor.me/exhibitions/${id}`,
        );
        if (data.press.M) {
          data.press.M = transform.aws.objectToArrayOfArrayPairs(data.press.M);
        }
        dispatch(data);
      } catch (err) {
        console.error(err);
      }
    };
    if (!exhibitions[id]?.artists) {
      getExhibition();
    }
  }, []);

  return exhibitions[id]?.artists ? (
    <Content isLoading={loading}>
      <Header>
        <h1>{exhibitions[id].title.S}</h1>
        <h2>{exhibitions[id].artists.SS.join(", ")}</h2>
        <h3>{exhibitions[id].date.S}</h3>
      </Header>
      <MediaWrapper spaceBetweenActive={spaceBetweenActive}>
        {render.media(exhibitions[id].work.L, setLoading)}
      </MediaWrapper>
      <TextContainer>
        {render.lineFeedsAndImages(exhibitions[id].statement.S)}
        {exhibitions[id].bio.S && (
          <>
            <p>~</p>
            {render.lineFeedsAndImages(exhibitions[id].bio.S)}
          </>
        )}
        {exhibitions[id].press.M &&
          exhibitions[id].press.M.map((link, i) => (
            <a
              href={link[1]}
              key={`a-${i}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {link[0]}
            </a>
          ))}
      </TextContainer>
      <ActionsContainer darkMode={darkMode}>
        <ActionButton onClick={toggleSpaceBetween} />
        <ActionButton onClick={toggleDarkMode} />
      </ActionsContainer>
    </Content>
  ) : (
    <Spinner />
  );
};

import React from "react";
import { Logo } from "../components";
import styled, { keyframes } from "styled-components";

// todo - add "we're on hiatus message"
// todo - add current exhib and future exhib (once we get back into programming)

const spin = keyframes`
0% {
  transform: rotate3d(0, 0, 0, 0)
}
50% {
  transform: rotate3d(0, 2, 1, 180deg)
}
100% {
  transform: rotate3d(0, 2, 1, 360deg)
}
`;

const View = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
`;

const SpinningLogo = styled.div`
  animation: ${spin} 10s linear infinite;
  font-size: 15vw;
  position: absolute;
  transform-style: preserve-3d;
  width: 30%;
`;

const Letter = styled.div`
  position: absolute;
  transform: rotateY(${({ t }) => t}deg) translateZ(30vw);
  width: 100%;
`;

export const Home = () => {
  const title = "CertifiedGoodEnough";
  return (
    <View>
      <SpinningLogo>
        {title.split("").map((letter, i, arr) => (
          <Letter
            key={`${letter}-${i}`}
            index={arr.length - i}
            t={(360 / arr.length) * i}
          >
            {letter}
          </Letter>
        ))}
        <Logo />
      </SpinningLogo>
    </View>
  );
};

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  #root {
    height: 100vh;
  }

  a {
    color: black;
    transition: color 0.5s;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
    transition: background 0.5s, color 0.5s;
    &.dark-mode {
      background: black;
      color: white;
      a {
        color: white;
      }
    }
  }

  button {
    border: 0;
    padding: 0;
  }

  iframe {
    border: none;
  }

`;

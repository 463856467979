import React from "react";
import styled, { keyframes } from "styled-components";
import { Logo } from "../components";

const spin = keyframes`
0% {
  transform: rotate3d(0, 0, 0, 0)
}
50% {
  transform: rotate3d(0, 2, 1, 180deg)
}
100% {
  transform: rotate3d(0, 2, 1, 360deg)
}
`;

const SpinningLogo = styled.div`
  animation: ${spin} 10s linear infinite;
  pointer-events: none;
  position: absolute;
  top: -5em;
  transform-style: preserve-3d;
  width: 80%;
`;

const View = styled.main`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em;
  max-width: 400px;
  position: relative;
  text-align: center;
  section {
    margin-bottom: 1em;
    width: 100%;
    label {
      display: block;
      margin-bottom: 0.5em;
    }
    a {
      color: black;
      margin-left: 1em;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const About = () => {
  return (
    <View>
      <Container>
        <SpinningLogo>
          <Logo />
        </SpinningLogo>
        <p>
          Good Enough is an artist-run project space that inhabits the residual
        </p>
        <section>
          <label>Follow:</label>
          <a
            className="link"
            href="https://www.instagram.com/goodenoughfor.me"
            target="_blank"
            rel="noreferrer"
          >
            @goodenoughfor.me
          </a>
        </section>
      </Container>
    </View>
  );
};

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Contatiner = styled.nav`
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 1em;
  position: fixed;
  a {
  }
  @media (max-width: 815px) {
    position: relative;
  }
`;

const NavLink = styled(Link)`
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const A = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Nav = () => (
  <Contatiner>
    <NavLink to="/">Home</NavLink>
    <NavLink to="/exhibitions">Exhibitions</NavLink>
    {/* <A href="https://www.mixcloud.com/goodenoughforme/" target="_blank">
      Mixes
      </A>
    */}
    <NavLink to="/about">About</NavLink>
    <div>-</div>
    <A href="https://arse.goodenoughfor.me/" target="_blank">
      ARSE
    </A>
  </Contatiner>
);

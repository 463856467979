import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { GlobalStyle } from "./styles";
import { About, App, Exhibition, Exhibitions, Home, NotFound } from "./views";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "exhibitions",
        element: <Exhibitions />,
      },
      {
        path: "exhibitions/:id",
        element: <Exhibition />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <>
    <GlobalStyle />
    <RouterProvider router={router} />
  </>,
);

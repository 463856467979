import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const VideoWrapper = styled.div`
  margin: 0.5em 0 0.75em;
  max-width: 600px;
  position: relative;
  width: 100%;
  &.ratio5-4 {
    height: calc(100vw * 0.8);
    max-height: 453px;
  }
  &.ratio16-9 {
    height: calc(100vw * 0.5625);
    max-height: 337.5px;
  }
  &.ratio4-3 {
    height: calc(100vw * 0.75);
    max-height: 450px;
  }
  iframe {
    height: 100%;
    width: 100%;
  }
`;

export const Video = ({ ratio, src, title }) => (
  <VideoWrapper className={`ratio${ratio}`}>
    <iframe src={src} title={title} allow="autoplay" />
  </VideoWrapper>
);

Video.propTypes = {
  ratio: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
};

import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
0% {
  transform: rotate3d(0, 0, 0, 0)
}
100% {
  transform: rotate3d(0, 0, 1, 360deg)
}
`;

const Container = styled.div`
  animation: ${spin} 10s linear infinite;
  height: 30px;
  margin: 1em;
  width: 30px;
`;

import { Logo } from "./";

export const Spinner = () => {
  return (
    <Container>
      <Logo />
    </Container>
  );
};

import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Nav } from "../components";
import { ExhibitionsProvider } from "../context";

export const App = () => {
  const location = useLocation();

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("dark-mode")) {
      body.classList.remove("dark-mode");
    }
  }, [location]);

  return (
    <ExhibitionsProvider>
      <Outlet />
      <Nav />
    </ExhibitionsProvider>
  );
};

import React from "react";
import styled from "styled-components";
import icon from "../assets/images/err404.png";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const Icon = styled.img`
  width: 100px;
`;

export const NotFound = () => {
  return (
    <Container>
      <Icon src={icon} alt="icon" />
      <p>Sorry this page does not exist</p>
      <a href="/">Home</a>
    </Container>
  );
};

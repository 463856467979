import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ImageContainer = styled.div`
  margin: 0.5em 0;
  max-width: 600px;
  position: relative;
  div {
    bottom: 0;
    color: white;
    margin: 1em;
    position: absolute;
  }
  img {
    width: 100%;
    height: auto;
    transition: all 1s;
  }
`;

const ImageInfo = styled.div`
  transition: opacity 0.5s;
  p {
    margin: 0;
  }
`;

export const Image = ({
  data: { alt, artist, date, filename, title, medium },
  onLoad,
}) => {
  const [dragging, setDragging] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const src = `https://s3.amazonaws.com/good-enough/${filename.S}`;

  const opacityHandler = () => {
    if (!dragging) setOpacity(1 - opacity);
    setDragging(false);
  };

  // touch handler for swipe scrolling on mobile
  const touchMoveHandler = () => {
    setDragging(true);
  };

  return (
    <ImageContainer
      onMouseOut={opacityHandler}
      onMouseOver={opacityHandler}
      onTouchEnd={opacityHandler}
      onTouchMove={touchMoveHandler}
    >
      <img alt={alt.S} loading="lazy" onLoad={onLoad} src={src} />
      {title ? (
        <ImageInfo style={{ opacity: opacity }}>
          {artist ? <p>{artist.S}</p> : null}
          <p>
            {title.S}
            {date ? `, ${date.S}` : ""}
          </p>
          {medium ? <p>{medium.S}</p> : null}
        </ImageInfo>
      ) : null}
    </ImageContainer>
  );
};

Image.propTypes = {
  data: PropTypes.object,
  onLoad: PropTypes.func,
};

import React, { useEffect, useState } from "react";
import axios from "axios";
import { animated, useTrail } from "react-spring";
import styled from "styled-components";

import { useExhibitions, useExhibitionsDispatch } from "../context";
import { Spinner, ExhibitionsList } from "../components";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow: scroll;
  width: 100%;
`;

const CursorImg = styled(animated.img)`
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity 0.5s;
  width: 200px;
  z-index: -1;
`;

const translate3d = (x, y) => {
  return `translate3d(${x - 100}px, ${y / 3}px, 0)`;
};

export const Exhibitions = () => {
  const exhibitions = useExhibitions();
  const dispatch = useExhibitionsDispatch();
  const [loading, setLoading] = useState(true);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [trails, api] = useTrail(2, () => ({
    xy: [window.innerWidth / 2, window.innerHeight],
    config: { friction: 60, mass: 1, tension: 170 },
  }));

  const onMouseMove = (e) => {
    api.start({ xy: [e.clientX, e.clientY] });
  };

  useEffect(() => {
    const getExhibitionsList = async () => {
      try {
        const { data } = await axios.get(
          "https://api.goodenoughfor.me/exhibitions/list",
        );
        dispatch(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    if (exhibitions.length <= 1 || exhibitions.includes(undefined)) {
      getExhibitionsList();
    } else {
      setLoading(false);
    }
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <Container onMouseMove={onMouseMove}>
      {exhibitions && (
        <ExhibitionsList list={exhibitions} setThumbnailUrl={setThumbnailUrl} />
      )}
      {trails.map(({ xy }, index) =>
        index === 1 ? (
          <CursorImg
            key={index}
            id="thumbnail"
            style={{
              transform: xy.to(translate3d),
            }}
            src={thumbnailUrl}
          />
        ) : null,
      )}
    </Container>
  );
};

import React from "react";
import styled from "styled-components";

const SVG = styled.svg`
  width: 100%;
  path:first-child {
    fill: transparent;
    stroke: black;
    mix-blend-mode: difference;
    stroke-width: 90;
  }
`;

export const Logo = () => (
  <SVG viewBox="0 0 1000 1000">
    <path d="M45,500c0,251.3,203.7,455,455,455s455-203.7,455-455c0-251.3-203.7-455-455-455 S45,248.7,45,500z" />
    <path d="M423.3,450.5c-33.3,9.8-60.1,27.1-81.6,52.3c-18.9,22.1-29.8,47.2-27.1,77 c1.4,14.7,6.3,28.3,14.4,40.6c17.1,25.7,38.9,45.7,69.5,54c30.4,8.2,57.3,0,80.6-20.1c9.2-7.9,16.7-17.8,24.5-27.1 c1.3-1.5,1.4-6.1,0.1-7.5c-13.5-14.1-27.5-27.9-41.1-41.5c-11.2,11.2-22,22.1-32.6,32.7c-14.8-15-28.1-28.4-40.8-41.2 c28.3-28.3,57.2-57.2,86.5-86.5c28,28,56.9,57,85.7,86c10,10.1,20.2,20,30,30.4c1.6,1.7,2.7,5.8,1.8,7.4 c-33.4,55.3-74.6,103.3-133.5,132.7c-52.6,26.2-102.5,18.8-148.4-16.8c-28.4-22-51.3-48.6-64.3-82.8c-17.3-45.6-9.8-88.7,14.7-129.3 c28.5-47.2,69.3-81.4,117-108c1.6-0.9,5.3-0.7,6.3,0.4C397.8,418.5,410.1,434,423.3,450.5z" />
    <path d="M570.8,378.4c26.6-26.6,53.2-53.2,79.6-79.6c13.8,13.8,27.4,27.3,41.4,41.2 c-26.4,26.4-53,53-79.5,79.5c16.4,16,32.3,31.5,48.3,47.2c26.7-26.7,54.6-54.6,82.2-82.2c13.7,13.9,27.1,27.3,40,40.3 c-46.7,46.7-94,93.9-140.8,140.6c-71.6-71.7-143.9-144.1-215.8-216c46.6-46.6,93.9-93.9,140.9-140.9c12.5,12.9,25.9,26.7,40,41.2 c-26.8,26.7-54.8,54.6-82.7,82.4C539.9,347.5,554.9,362.6,570.8,378.4z" />
  </SVG>
);

import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

const initialExhibitions = [];
const ExhibitionsContext = createContext(null);
const ExhibitionsDispatchContext = createContext(null);

export function useExhibitions() {
  return useContext(ExhibitionsContext);
}

export function useExhibitionsDispatch() {
  return useContext(ExhibitionsDispatchContext);
}

function exhibitionsReducer(exhibitions, newExhibitions) {
  if (Array.isArray(newExhibitions)) {
    return newExhibitions.map((exhibition, i) => ({
      ...exhibitions[i],
      ...exhibition,
    }));
  }
  const id = parseInt(newExhibitions.id.N);
  const updatedExhibitions = [...exhibitions];
  updatedExhibitions[id] = {
    ...updatedExhibitions[id],
    ...newExhibitions,
  };
  return updatedExhibitions;
}

export function ExhibitionsProvider({ children }) {
  const [exhibitions, dispatch] = useReducer(
    exhibitionsReducer,
    initialExhibitions,
  );
  return (
    <ExhibitionsContext.Provider value={exhibitions}>
      <ExhibitionsDispatchContext.Provider value={dispatch}>
        {children}
      </ExhibitionsDispatchContext.Provider>
    </ExhibitionsContext.Provider>
  );
}

ExhibitionsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
